import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import axios from "axios";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

export const fetchEmpType = createAsyncThunk("emptype/fetchData", async () => {
  if (sessionDecrypt(sessionStorage.getItem("token")) &&sessionDecrypt(sessionStorage.getItem('userId'))) {
    try {
      const token = sessionDecrypt(sessionStorage.getItem("token"));
      const homeLang =sessionDecrypt(sessionStorage.getItem("HLang"));

      const response = await axios.get(
        `${BASE_URL}/skill/api/v1/skills/AdvancedData/EmpType Map`,
        {
          params: {
            searchFieldName: "mlanguage",
            searchValue: homeLang,
            authToken: token,
          },
        }
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
});

const empTypeSlice = createSlice({
  name: "emptype",
  initialState: {
    status: "idle",
    error: null,
    data: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmpType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmpType.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload?.filter((type) => type?.active === "Yes");
      })
      .addCase(fetchEmpType.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default empTypeSlice.reducer;
