import { lazy } from "react";
import ConsoleLayout from "../../views/consoles/ConsoleLayout";

const consoleRoutes = [
  {
    exact: "true",
    path: "attachment",
    layout: ConsoleLayout,
    element: lazy(() =>
      import("../../views/consoles/attachment console/AttachmentConsole")
    ),
  },
  {
    exact: "true",
    path: "validations",
    layout: ConsoleLayout,
    element: lazy(() =>
      import("../../views/consoles/validation console/ValidationConsole")
    ),
  },
  {
    exact: "true",
    path: "view-validation",
    layout: ConsoleLayout,
    element: lazy(() =>
      import("../../views/consoles/validation console/ValidationAdditionalDetails")
    ),
  },
];

export default consoleRoutes;
