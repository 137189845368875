import axios from "axios";
import { BASE_URL } from "../../config/Properties";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

export const fetchUsersBasedOnSkillSearch = createAsyncThunk(
  "SkillSearch/fetchUsers",
  async (body) => {
    try {
      const headers = {
        Authorization: "Bearer " + sessionDecrypt(sessionStorage.getItem("token")),
      };
      console.log("api calling ", body);
      const response = await axios
        .post(`${BASE_URL}/skill/api/v1/skills/querry/skills/${body.title}?start=${body.start}&size=${body.size}&sortField=${body.sortField}&sortOrder=${body.sortOrder}`, body.body, { headers })
        .then((res) => {

          return res;
        });



      return response;
    } catch (error) {
      throw error;
    }
  });


