import { useSelector } from "react-redux";

const useContentLabel = () => {
  /* STORE IMPORTS */
  const selectedLanguage = useSelector((state) => state?.language);
  const content = useSelector((state) => state?.content);



  return (elementLabel, defaultValue) => {

    const redirect = localStorage.getItem("info");

    if (redirect && redirect !== "") {

      const reNameElementLabel = elementLabel + "-" + redirect;
      const redirectValue = content[selectedLanguage]?.find(
        (val) => val?.elementLabel === reNameElementLabel
      )?.mvalue;

      if (redirectValue) {        
        return redirectValue;
      }

    }

    const label = content[selectedLanguage]?.find(
      (val) => val?.elementLabel === elementLabel
    );
    return label?.mvalue || defaultValue;
  };
};

export default useContentLabel;
