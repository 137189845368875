import axios from "axios";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

const LocationSuggestionApi = async (query, searchField,countryCode) => {

  const SEARCH_URI = BASE_URL + `/skill/api/v1/skills/searchLocations/Map Database?searchFieldName=${searchField}&authToken=${sessionDecrypt(sessionStorage.getItem("token"))}`;
  return axios
    .get(`${SEARCH_URI}&searchValue=${query}&countryCode=${countryCode}`)
    .then((response) => {
      console.log("response", response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default LocationSuggestionApi;
