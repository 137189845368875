import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

export const fetchCourseApplicationList = createAsyncThunk(
    "fetchCourseApplicationList/fetchData",
    async (body) => {
        try {
            const token = sessionDecrypt(sessionStorage.getItem("token"));
            const userId =sessionDecrypt(sessionStorage.getItem('userId'));

            // const response = await fetch(`${BASE_URL}/skill/api/v1/skills/jd/Jdapplication?filter=jid%3A${body.jid}${body.preFilter ? body.preFilter : ""}${body.filter ? body.filter : ""}&start=${body.start}&size=${body.size}&sortField=${body.sortField}&sortOrder=${body.sortOrder}&authToken=${token}`);
            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/Courses/CourseResponse?filter=userCourseId%3A${body.courseId}%7C&start=${body.start}&size=${body.size}&sortField=${body.sortField}&sortOrder=${body.sortOrder}&authToken=${token}`);
            // const response = await fetch( `${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/${'Course Response'}/${userId}?authToken=${token}`,);

            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
);
