import React from "react";
import { logoutUser } from "../../Store";
import { useDispatch } from "react-redux";
import { logout } from "../../reducer/userDetails/UserProfileSlice";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

const SessionPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Time settings in milliseconds
  const timeout = 1000 * 60 * 60 * 3; // 3 hours
  const promptBeforeIdle = 1000 * 60 * 60 * 2.5; // 2.5 hours

  const handleLogout = () => {
    console.log("user is idle loggedout");
    logoutUser();
    dispatch(logout());
    sessionStorage.clear();
    navigate("/login");
  };

  // show when user is idle show popup
  const handleOnPrompt = () => {
    console.log("user is idle half minute pop up");
    // show modal
    document.getElementById("sessionPopUp").click();
  };

  const handleOnIdle = () => {
    console.log("user is idle so init logout");

    document.getElementById("sessionClose").click();
    // show modal
    handleLogout();
  };

  const handleStayLoggedIn = () => {
    console.log("user is want to stay logged in");
    // reset idle timer
    reset();
  };

  const handleOnActive = () => {
    console.log("User is active again");
  };

  const { reset } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt: handleOnPrompt, // Show popup at 15 seconds
    onIdle: handleOnIdle, // Log out at 30 seconds
    onActive: handleOnActive, // Print when user becomes active
    throttle: 500,
  });

  return (
    <>
      {/*  hidden  */}
      <button
        type="button"
        class="d-"
        data-bs-toggle="modal"
        data-bs-target="#sessionModal"
        id="sessionPopUp"
      ></button>

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="sessionModal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="sessionModal"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="sessionModal">
                Your session is about to expire
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                id="sessionClose"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>
                Your session is about to expire. You'll be automatically signed
                out.
              </p>
              <p>Do you want to stay signed in?</p>{" "}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleLogout}
              >
                Sign out now
              </button>
              <button
                type="button"
                class="btn btn-primary "
                data-bs-dismiss="modal"
                onClick={handleStayLoggedIn}
                aria-label="Close"
              >
                Stay signed in
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionPopup;
