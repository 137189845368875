import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

export const fetchUserAttachment = createAsyncThunk(
  "attachment/fetchData",
  async () => {
    if (sessionDecrypt(sessionStorage.getItem("token")) &&sessionDecrypt(sessionStorage.getItem('userId'))) {
      try {
        const res = await axios.get(
          `${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/Attachment Map/${sessionDecrypt(sessionStorage.getItem(
            "userId"
          ))}?authToken=${sessionDecrypt(sessionStorage.getItem("token"))}`
        );
        console.log("KKKK", res?.data);
        return res.data;
      } catch (error) {
        throw error;
      }
    }
  }
);

const getUserAttachmentSlice = createSlice({
  name: "userAttachment",
  initialState: {
    userAttachmentData: [],
    loading: false,
    error: null,
  },
  reducers: {
    editAttachmentData: (state, action) => {
      state.userAttachmentData = state.userAttachmentData.map((req) => {
        if (req.id === action.payload.id) {
          return {
            ...req,
            ...action.payload,
          };
        }
        return req;
      });
      state.loading = "success";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAttachment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserAttachment.fulfilled, (state, action) => {
        state.loading = false;
        state.userAttachmentData = action.payload;
      })
      .addCase(fetchUserAttachment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { editAttachmentData } = getUserAttachmentSlice.actions;
export default getUserAttachmentSlice.reducer;
