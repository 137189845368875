import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { useSelector } from 'react-redux';
import { sessionDecrypt } from "../../config/encrypt/encryptData";
export const GetAllExternalLinksApi = createAsyncThunk("externalLink/externalLink", async () => {
    // console.log("selected Lang: ");
   
    try {


        const response = await fetch(`${BASE_URL}/skill/api/v1/skills/get-master-data/ExtSites`);

        const data = await response.json();
        console.log("datas ", data);
        return data;
    } catch (error) {
        throw error;
    }
});