import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { GetAttachment } from "../../../api/Attachment  API/DownloadAttachmentApi";
import { icons } from "../../../constants";
import useContentLabel from "../../../hooks/useContentLabel";
import CancelButton from "../../atoms/Buttons/CancelButton";
import CustomButton from "../../atoms/Buttons/CustomButton";
import AddNewAttachmentComponent from "../../Attachment/AddNewAttachmentComponent";
import LazyLoadingImageComponent from "../../Lazy Loading Images/LazyLoadingImageComponent";
import { Link } from "react-router-dom";
import { sessionDecrypt } from "../../../config/encrypt/encryptData";
const Gallery = ({
  title,
  openGallery,
  handleGalleryClose,
  handleSelectImage,
  isMulti = false,
}) => {
  const contentLabel = useContentLabel();
  const role_ = sessionDecrypt(sessionStorage.getItem("USER_ROLE"));
  const userId_ = sessionDecrypt(sessionStorage.getItem("userId"));

  const {
    getUserAttachment: { userAttachmentData },
  } = useSelector((state) => state);

  const [fileChosen, setFileChosen] = useState(isMulti ? [] : null);
  const [imageErrors, setImageErrors] = useState({});

  const filteredData = useMemo(() => {
    return userAttachmentData?.filter(
      (att) =>
        att?.linkedApplicationName === "User Details" &&
        att?.roleName === role_ &&
        att?.fileType?.startsWith("image") &&
        att?.userId === userId_
    );
  }, [userAttachmentData, role_, userId_]);

  const handleImageError = (index) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [index]: true,
    }));
  };

  const handleFileSelect = useCallback(() => {
    handleSelectImage(isMulti ? fileChosen : fileChosen);

    handleGalleryClose();
    setFileChosen(isMulti ? [] : null);
  }, [fileChosen, handleGalleryClose, handleSelectImage, isMulti]);

  const toggleFileSelection = (file) => {
    if (isMulti) {
      setFileChosen((prev) => {
        if (prev.some((f) => f.fileId === file.fileId)) {
          return prev.filter((f) => f.fileId !== file.fileId);
        }
        return [...prev, file];
      });
    } else {
      setFileChosen(file);
    }
  };

  const isFileSelected = (file) => {
    return isMulti
      ? fileChosen.some((f) => f.fileId === file.fileId)
      : fileChosen?.fileId === file.fileId;
  };

  return (
    <Dialog
      open={openGallery}
      onClose={handleGalleryClose}
      maxWidth="lg"
      fullWidth
      aria-labelledby="Gallery-dialog"
      PaperProps={{
        style: {
          height: "80vh",
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle
        id="course-application-dialog"
        display={"flex"}
        justifyContent={"space-between"}
      >
        {contentLabel("Gallery", "nf Gallery")}
        <icons.FaTimes
          className="cursor-pointer"
          onClick={handleGalleryClose}
        />
      </DialogTitle>
      <DialogContent dividers sx={{ overflowY: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {title}
          </Grid>

          {/* IMAGES */}
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: "flex", flexFlow: "wrap", gap: 2 }}
          >
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((file, index) => {
                const imageUrl = GetAttachment(
                  file.userId,
                  file.fileName,
                  file.fileId
                );
                const isSelected = isFileSelected(file);

                return (
                  <div
                    key={index}
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundSize: "cover",
                      margin: "0 5px",
                      position: "relative",
                      cursor: "pointer",
                      boxShadow: isSelected
                        ? `0px 0px 3px 2px #778899`
                        : "none",
                    }}
                    className="rounded mt-1 p-1 border"
                    onClick={() => toggleFileSelection(file)}
                  >
                    <LazyLoadingImageComponent
                      src={imageUrl}
                      alt="attachment"
                      onError={() => handleImageError(index)}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: imageErrors[index] ? "none" : "block",
                      }}
                    />
                    {imageErrors[index] && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#f0f0f0",
                          color: "#333",
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {contentLabel(
                            "ImageNotAvailable",
                            "nf Image Not Available"
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="text-center">
                {contentLabel("NoAttachmentsFound", "nf No Attachments Found")}
                &nbsp;
                <Link to={"/console/attachment"}>
                  {contentLabel(
                    "UploadAttachmentsHere",
                    "nf Upload attachments here..."
                  )}
                </Link>
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      {/* BUTTONS */}
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid>
          <AddNewAttachmentComponent />
          <em>{contentLabel("ImageNote", "ImageNote")}</em>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <CancelButton onClick={handleGalleryClose}></CancelButton>
          <CustomButton
            title={contentLabel("Next", "nf Next")}
            onClick={handleFileSelect}
            disabled={isMulti ? fileChosen.length === 0 : !fileChosen}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Gallery;
