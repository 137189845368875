import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-datepicker/dist/react-datepicker.css'
import UserDetailsApi from "./api/auth/UserDetailsApi";
import App from "./App";
import "./index.css";
import "./index.scss";
import { setUserProfile } from "./reducer/userDetails/UserProfileSlice";
import reportWebVitals from "./reportWebVitals";
import store from "./Store";

import { Tooltip } from "react-tooltip";

import axios from "axios";
import { BASE_URL } from "./config/Properties";

import { ConfigProvider } from "./context/ConfigContext";
import { DirectionProvider } from "./context/DirectionContext";
import { setUserRoles } from "./reducer/roles/RoleMappingSlice";
import { sessionDecrypt } from "./config/encrypt/encryptData";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (sessionDecrypt(sessionStorage.getItem("token"))) {
  // allow user and go to dashboard page

  if (sessionDecrypt(sessionStorage.getItem('userId')) && sessionDecrypt(sessionStorage.getItem("userName"))) {
    //http://localhost:8080/skill/api/v1/skills/AcccountDetails/PRIYAJANSIUSER
    try {
      axios
        .get(
          `${BASE_URL}/skill/api/v1/skills/AcccountDetails/${sessionDecrypt(sessionStorage.getItem(
            "userName"
          ))}`
        )
        .then((res) => {
          console.log("initail account det ", res);

          store.dispatch(setUserRoles(res?.data?.account?.roles));
        });
    } catch (error) {
      console.log("initial errp r", error);
    }
    UserDetailsApi().then((res) => {
      if (res.status === 200) {
        // set state of user details
        // const dispatch = useDispatch();
        // dispatch(setUserProfile(res.data[0].allValues));
        const userDetails = {
          ...res.data,
          token: sessionDecrypt(sessionStorage.getItem("token")),
        };
        store.dispatch(setUserProfile(userDetails));
      } else {
        // something went wrong redirect to login page
        // sessionStorage.clear();
      }
    });
  }
}

/* MAKE CONDITION FOR PROD TO AVOID CONSOLES */
// console.log = () => {};
// console.error = () => {};
// console.warn =() => {};

root.render(
  <Provider store={store}>
    <DirectionProvider>
      <ConfigProvider>
        <App />
        <ToastContainer />
        <Tooltip id="my-tooltip" />
      </ConfigProvider>
    </DirectionProvider>
  </Provider>
);
reportWebVitals();
