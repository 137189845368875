import { sessionDecrypt } from "../../../config/encrypt/encryptData";

export function formatTimestampToDate(timestamp, dateFormat = 'mm/dd/yyyy') {

  const dateFormatLc = sessionDecrypt(sessionStorage.getItem('dateFormat')) || dateFormat;
  const date = Number(timestamp) ? new Date(Number(timestamp)) : new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month is zero-based
  const year = date.getFullYear().toString().padStart(4, '0'); // Ensure year has 4 digits

  // Replace format tokens with corresponding date parts
  const formattedDate = dateFormatLc
    .replace('dd', day)
    .replace('mm', month)
    .replace('yyyy', year); // Use 'yyyy' for 4-digit year representation

  return formattedDate;
}

export function formatTimeStampTOHourMin(timeStamp, locale = 'en-US', timeZone = 'Asia/Kolkata') {
  // Convert epoch time to Date object
  const date = Number(timeStamp) && new Date(Number(timeStamp));

  // // Get hours and minutes
  // const hours = date.getUTCHours();
  // const minutes = date.getUTCMinutes();

  // // Format the time in `12h 15m` style
  // const formattedTime = `${hours}h ${minutes}m`;

  // return formattedTime;

  // Use toLocaleString to get time parts in the selected time zone
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Set to false for 24-hour format, true for 12-hour format
    timeZone: timeZone
  };

  // Extract time string based on locale and time zone
  const timeString = date.toLocaleString(locale, options);

  // Split the formatted time string (e.g., "10:15" or "22:15") into hours and minutes
  const [hours, minutes] = timeString.split(':');

  // Format the time in "10h 15m" style
  const formattedTime = `${hours}h ${minutes}m`;

  return formattedTime;

}
