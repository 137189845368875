import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  accountDetails: { userInfo: {} },
  status: "idle", // possible values: "idle", "loading", "success", "error"
  error: null,
};

const UserProfileSlice = createSlice({
  name: "UserProfile",
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.data = action.payload;
      state.status = "success";
      state.error = null;
    },
    setUserInfo: (state, action) => {
      state.accountDetails.userInfo = action.payload;

    },
    listaddNewUserProfile: (state, action) => {
      if (action.payload) {
        // -1 because state consists token as well
        const newUserId = Object.keys(state.data).length.toString() - 1;

        state.data = {
          ...state.data,
          [newUserId]: action.payload,
        };
      }
    },
    editExistingUserProfile: (state, action) => {
      const { id, updatedData } = action.payload;

      console.log("id", id);
      console.log("updatedData", updatedData);

      console.log(state.data);
      // Find the index of the item with the provided id
      const userDetailsArray = Object.values(state.data);

      const index = userDetailsArray.findIndex(
        (userObject) => userObject.id === id
      );

      console.log("index", index);

      // If the item is found, update it with the new data
      if (index !== -1) {
        state.data[index] = { ...updatedData };
        // state.data[index] = { ...state.data[index], ...updatedData };
      }
    },

    //   logout reset to initial state
    logout: () => initialState,
  },
});

export const { setUserProfile, logout, listaddNewUserProfile, editExistingUserProfile, setUserInfo } = UserProfileSlice.actions;
export default UserProfileSlice.reducer;
