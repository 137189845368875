import { combineReducers } from "redux";
import languageReducer from "./localization/languageSlice";
import directionReducer from "./localization/directionSlice";
import contentReducer from "./localization/contentSlice";
import userReducer from "./userDetails/UserProfileSlice";
import TopSkillReducer from "./mySkills/TopSkillSlice";
import SkillsApplied from "./skillProfile/SkillsAppliedSlice";
import SkillsAcquired from "./skillProfile/SkillsAcquiredSlice";
import CertificationHistory from "../reducer/detailedProfile/certificationSlice";
import TrainingHistory from "../reducer/detailedProfile/trainingSlice";
import ConferenceHistory from "../reducer/detailedProfile/conferenceSlice";
import ProjectHistory from "../reducer/detailedProfile/projectSlice";
import EmploymentHistory from "../reducer/detailedProfile/employmentSlice";
import EducationHistory from "../reducer/detailedProfile/educationSlice";
import MembershipHistory from "../reducer/detailedProfile/membershipSlice";
import SkillingsHistory from "../reducer/detailedProfile/skillingsSlice";
import LicensesHistory from "../reducer/detailedProfile/licensesSlice";
import OtherHistory from "../reducer/detailedProfile/otherSlice";
import SkillSelectedSlice from "./mySkills/SkillSelectedSlice";
import langListReducer from "./localization/listOfLangSlice";
import RoleMappingSlice from "./roles/RoleMappingSlice";
import genderMapSlice from "./gender/genderMapSlice";

import skillOwnerAttachmentSlice from "./attachments/skillOwnerAttachments";

import courseDataSlice from "./skilling agency/course data/courseDataSlice";
import createCourseSlice from "./skilling agency/create course/createCourseSlice";
import courseApplicationSlice from "./skilling agency/candidate management/courseApplicationSlice";
import courseShortlistSlice from "./skilling agency/candidate management/courseShortlistSlice";
import courseRejectedSlice from "./skilling agency/candidate management/courseRejectedSlice";
import courseScreeningSlice from "./skilling agency/candidate management/courseScreeningSlice";
import courseSelectedSlice from "./skilling agency/candidate management/courseSelectedSlice";
import AgencySavedSearchSlice from "./skilling agency/search/AgencySavedSearchSlice";



import selectedCourseApplication from "./SkillingAgency/CandidateManagement/selectedCourseApplicationSlice";
import myCourseReducer from '../reducer/SkillingAgency/features/course/mycourse/myCourseSlice'
import editCourseReducer from './SkillingAgency/CourseSearch/editCourseSlice'
import userCoursesReducer from './SkillingAgency/CourseSearch/userCoursesSlice'
import myCurrencyReducer from '../reducer/SkillingAgency/features/course/currency/myCurrencySlice'
import SkillBasedResult from "./SkillSeeker/SkillBasedSearch/SkillBasedResultSlice";
import MyRequirement from "./SkillSeeker/SkillBasedSearch/MyRequirementSkillSlice";
import RefMyRequirements from "./SkillSeeker/SkillBasedSearch/RefMyRequirementsSkillSlice";
import regionalData from "./localization/CountryRegionalSlice";
import categorySlice from "./nf specific/categorySlice";
import languageKnownSlice from "./detailedProfile/languageKnownSlice";
import achievementHistory from "./detailedProfile/achievementSlice"
import detailedProfileReportSlice from './report views/detailed profile report/detailedProfileReportSlice'

/* SKILL VALIDATION */
import validationSlice from "./validation/validationSlice";
import validationUploadSlice from "./validation/valdidationUploadSlice";
import getUserValidationSlice from "./validation/getUserValidationSlice";
import deleteDetailedProfileSlice from "./delete/deleteDetailedProfileSlice";

/* SKILLS APPLIED EDIT DATA */
import skillsAppliedEditSlice from "./edit/skillsAppliedEditSlice";
/* SKILLS ACQUIRED EDIT DATA */
import skillAcquiredEditSlice from "./edit/skillAcquiredEditSlice";
/* DETAILED PROFILE EDIT DATA */
import detailedProfileEditSlice from "./edit/detailedProfileEditSlice";
import UserAccountDetailsSlice from "./userDetails/UserAccountDetailsSlice";

/* SELECTED OPPORTUNITY DATA */
import selectedOpportunity from "./opportunities/selectedOpportunity";

/* SELECTED UPSKILLING DATA */
import selectedUpSkilling from "./upskilling/selectedUpSkilling";


/* SET SKILLS FOR RESUME SKILL PROFILE */
import resumeSkillSlice from "./resume/resumeSkillSlice";

/* SCREEN NAMEeeeee */
import screenNameSlice from "./screen/screenNameSlice";
/* COLLECTIVE ATTACHMENTS [ATTACHMENT CONOLE] */
import getUserAttachmentSlice from "./attachments/getUserAttachmentSlice";
import JdDataSlice from "./SkillSeeker/JdData/JdDataSlice";
import JdApplication from "./SkillSeeker/JdData/JdApplicationSlice";
import JdApplicationSlice from "./SkillSeeker/JdData/JdApplicationSlice";
import UserCompanyDataSlice from "./SkillSeeker/JdData/UserCompanyDataSlice";
import JdViewConfigSlice from "./SkillSeeker/JdData/JdViewConfigSlice";
import OpporSelectedSlice from "./SkillSeeker/JdData/OpporSelectedSlice";
import OpporShortlistSlice from "./SkillSeeker/JdData/OpporShortlistSlice";
import OpporScreeningSlice from "./SkillSeeker/JdData/OpporScreeningSlice";
import OpporRejectedSlice from "./SkillSeeker/JdData/OpporRejectedSlice";
import OpportunityCreationSlice from "./SkillSeeker/JdData/OpportunityCreationSlice";
import userDetailedProfileSLice from './detailed profile/userDetailedProfilSlice'
/* VIEW DATA SLICE */
import viewDataSlice from "./view data/viewDataSlice";
import editModeSlice from './edit/isEditSlice';
import OpportunityEditSlice from "./SkillSeeker/JdData/OpportunityEditSlice";

import languageListSlice from "./language list/languageListSlice";
import SavedSearchSlice from "./SkillSeeker/SkillBasedSearch/SavedSearchSlice";
import menuItemR1Slice from "./menuItems/menuItemR1Slice";

import empTypeSlice from "./emp type/empTypeSlice";
import CategoryAndSubCategorySlice from "./localization/CategoryAndSubCategorySlice";
import ExternalLinksSlice from './localization/ExternalLinksSlice';
import JdExperienceLevelSlice from './SkillSeeker/JdData/JdExperienceLevelSlice';


const rootReducer = combineReducers({
  UserCompanyDataSlice: UserCompanyDataSlice,
  achievementHistory: achievementHistory,
  languageKnown: languageKnownSlice,
  regionalData: regionalData,
  RefMyRequirements: RefMyRequirements,
  MyRequirement: MyRequirement,
  SkillBasedResult: SkillBasedResult,
  langList: langListReducer,
  language: languageReducer,
  direction: directionReducer,
  content: contentReducer,
  SkillSelected: SkillSelectedSlice,
  userProfile: userReducer,
  TopSkill: TopSkillReducer,
  SkillsApplied: SkillsApplied,
  SkillsAcquired: SkillsAcquired,
  certificationHistory: CertificationHistory,
  trainingHistory: TrainingHistory,
  licensesHistory: LicensesHistory,
  conferenceHistory: ConferenceHistory,
  projectHistory: ProjectHistory,
  employmentHistory: EmploymentHistory,
  educationHistory: EducationHistory,
  membershipHistory: MembershipHistory,
  skillingsHistory: SkillingsHistory,
  otherHistory: OtherHistory,
  roles: RoleMappingSlice,
  gender: genderMapSlice,
  skillOwnerAttachments: skillOwnerAttachmentSlice,
  UserAccountDetails: UserAccountDetailsSlice,
  detailedProfileReportSlice: detailedProfileReportSlice,

  //OPPORTUNITY
  selectedOpportunity: selectedOpportunity,
  //OPPORTUNITY
  selectedUpSkilling: selectedUpSkilling,
  /* SPECIFIC CATEGORY */
  category: categorySlice,

  //COURSES
  myCourses: courseDataSlice,
  createCourse: createCourseSlice,
  courseApplication: courseApplicationSlice,
  courseShortlist: courseShortlistSlice,
  courseRejected: courseRejectedSlice,
  courseSelected: courseSelectedSlice,
  courseScreening: courseScreeningSlice,
  agencySavedSearch: AgencySavedSearchSlice,

  // //COURSES (no more in use)
  // editCourse: editCourseReducer,
  // userCourses: userCoursesReducer,
  // myCourse: myCourseReducer,
  // currency: myCurrencyReducer,
  // selectedCourseApplication: selectedCourseApplication,



  /* VALIDATION */
  skillValidation: validationSlice,
  validationUploadData: validationUploadSlice,
  getUserValidation: getUserValidationSlice,

  deleteDetailedProfile: deleteDetailedProfileSlice,

  /* SKILLS APPLIED EDIT */
  skillsAppliedEdit: skillsAppliedEditSlice,
  /* SKILLS ACQUIRED EDIT */
  skillsAcquiredEdit: skillAcquiredEditSlice,
  /* DETAILED PROFILE EDIT */
  detailedProfileEdit: detailedProfileEditSlice,

  /* RESUME SKILLS */
  resumeSkills: resumeSkillSlice,

  /* SCREEN NAME */
  screenName: screenNameSlice,

  /* ATTACHMENT CONSOLE */
  getUserAttachment: getUserAttachmentSlice,

  // Opportunities Grid
  JdViewConfig: JdViewConfigSlice,
  JdDataSlice: JdDataSlice,
  SavedSearchSlice: SavedSearchSlice,
  JdApplicationSlice: JdApplicationSlice,
  OpporSelectedSlice: OpporSelectedSlice,
  OpporShortlistSlice: OpporShortlistSlice,
  OpporScreeningSlice: OpporScreeningSlice,
  OpporRejectedSlice, OpporRejectedSlice,
  OpportunityCreate: OpportunityCreationSlice,
  OpportunityEdit: OpportunityEditSlice,
  userDetailedProfileData: userDetailedProfileSLice,
  view_data: viewDataSlice,
  editMode: editModeSlice,
  languageLists: languageListSlice,

  menuItems: menuItemR1Slice,
  emptype: empTypeSlice,
  JobCategories: CategoryAndSubCategorySlice,
  ExternalLinks: ExternalLinksSlice,
  JdExperienceLevel: JdExperienceLevelSlice
});

export default rootReducer;