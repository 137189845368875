import React, { useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CheckoutStepper from '../../components/Steppers/Stepper';
import BackToTop from '../../components/BackToTop/BackToTop';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import icons from './../../constants/icons';
import useContentLabel from '../../hooks/useContentLabel';

const SkillingAgencyBreadcrumbs = ({ children }) => {

    const { pathname, search } = useLocation()
    const queryParams = new URLSearchParams(search);
    const navigate = useNavigate();
    const contentLabel = useContentLabel()
    const { selectedCourse, editCourse } = useSelector((state) => state.myCourses)
    const { selectedCourse : saveSelectedCourse } = useSelector((state) => state.agencySavedSearch)



    return (
        <div class="content-body">
            <div class="container-fluid">

                {/* <Outlet />  */}

                <div >
                    {

                        <div className='d-flex mb-1 mt-1 ms-2  ' style={{ color: "#875712", cursor: "pointer" }} >

                            {
                                (pathname.includes('candidate-management') || pathname.includes('my-courses')) && !queryParams.get("Type") &&
                                <React.Fragment>
                                    <p className='  text-decoration-underline fw-bold me-1  ' onClick={() => navigate('/skillingagency/my-courses')} >{contentLabel('MyCourses', 'nf My Courses')}</p>
                                </React.Fragment>
                            }
                            {
                                (pathname.includes('candidate-management') || pathname.includes('my-courses')) && queryParams.get("Type") === 'Search' &&
                                <React.Fragment>
                                    <p className='  text-decoration-underline fw-bold me-1  ' onClick={() => navigate('/skillingagency/Skill-Search/Saved-Searches')} >{contentLabel("SavedSearches", "nf Saved Searches")}</p>
                                </React.Fragment>
                            }

                            {
                                pathname.includes('create') &&


                                <React.Fragment>
                                    <icons.FaAngleRight size={22} /> <p className='fw-bold me-1'>{contentLabel('Create', 'nf Create')}</p>
                                </React.Fragment>

                            }

                            {
                                selectedCourse?.courseName &&
                               ( pathname.includes('/skillingagency/my-courses') || pathname.includes('candidate-management') ) && !pathname.includes('create') && !queryParams.get("Type") && !pathname.includes('edit') &&

                                <React.Fragment>
                                    <icons.FaAngleRight size={22} /> <p className='ms-1 fw-bold'>{selectedCourse?.courseName}</p>
                                </React.Fragment>

                            }
                            {
                                saveSelectedCourse?.courseName &&
                               ( pathname.includes('/skillingagency/my-courses') || pathname.includes('candidate-management') ) && !pathname.includes('create') && queryParams.get("Type") === 'Search' &&

                                <React.Fragment>
                                    <icons.FaAngleRight size={22} /> <p className='ms-1 fw-bold'>{saveSelectedCourse?.courseName}</p>
                                </React.Fragment>

                            }

                            {
                                pathname.includes('candidate-management') &&


                                <React.Fragment>
                                    <icons.FaAngleRight size={22} />  <p className=' fw-bold me-1'>{contentLabel('ManageCandidates', 'nf ManageCandidates')}</p>
                                </React.Fragment>

                            }

                            {
                                pathname.includes('edit') &&


                                <React.Fragment>
                                    <icons.FaAngleRight size={22} /> <p className='fw-bold me-1'>{contentLabel('Edit', 'nf Edit')}</p>
                                    <icons.FaAngleRight size={22} /> <p className='fw-bold me-1'>{editCourse?.courseName}</p>
                                </React.Fragment>


                            }

                            {
                                pathname.includes('Skill-Search') &&


                                <React.Fragment>
                                    <p className=' fw-bold me-1  '  >{contentLabel('SkillSearch', 'nf Skill Search')}</p>
                                </React.Fragment>

                            }

                            {
                                //   Access DB
                                pathname.includes('/skillingagency/Skill-Search/Access-Database')  ?


                                    <React.Fragment>

                                        <icons.FaAngleRight size={22} />    <p className='   fw-bold me-1  ' >{contentLabel("DatabaseAccess", "nf Database Access")}</p>

                                        {
                                            queryParams.get("courseName") && queryParams.get("Saved") &&
                                            <React.Fragment>

                                                <icons.FaAngleRight size={22} />    <p className='   fw-bold me-1  '
                                                //   onClick={() => navigate('/skillingagency/my-courses')}
                                                >{queryParams.get("courseName")}</p>
                                            </React.Fragment>
                                        }

                                    </React.Fragment>


                                    :
                                    //  Saved-Searches
                                    pathname.includes('/skillingagency/Skill-Search/Saved-Searches') &&


                                    <React.Fragment>
                                        <icons.FaAngleRight size={22} />    <p className='  fw-bold me-1  '  >{contentLabel("SavedSearches", "nf Saved Searches")}</p>
                                    </React.Fragment>

                            }

                        </div>
                    }


                    {children}

                </div>
            </div>
            {/* <BackToTop /> */}
        </div>
    )
}

export default SkillingAgencyBreadcrumbs