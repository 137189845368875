import { createSlice } from "@reduxjs/toolkit";
import { GetCompanyListByUserId } from "../../../api/SkillSeeker/GetCompanyListByUserId.js";
import { showErrorToast } from "../../../components/ToastNotification/showErrorToast.js";
import { BASE_URL } from "../../../config/Properties.js";
import { sessionDecrypt } from "../../../config/encrypt/encryptData.js";

const initialState = {
  CompanyList: [],
  companyLoading: false,
  companyApiStatus: "idle",
  companyApiError: null,
};
const UserCompanyDataSlice = createSlice({
  name: "UserCompanyDataSlice",
  initialState,
  reducers: {
    setCompanyList: (state, action) => {
      state.CompanyList = action.payload;
    },

    //   logout reset to initial state
    emptyCompanyData: () => {
      return initialState;
    },
    addCompany: (state, action) => {
      state.CompanyList.push(action.payload.data);
      console.log(action.payload.data);
    },
    attachLogoToCompany: (state, action) => {
      const data = action.payload;
      const userId =sessionDecrypt(sessionStorage.getItem('userId'));
      const auth = sessionDecrypt(sessionStorage.getItem("token"));

      // Ensure BASE_URL is defined elsewhere in your code.
      if (!userId || !auth || !BASE_URL) {
        console.error("Missing required parameters to generate file URL.");
        return;
      }

      const newState = state?.CompanyList?.map((com) => {
        const temp = data.find((atch) => atch.linkedId === com?.id);

        if (temp) {
          return {
            ...com,
            fileExist: true,
            fileUrl: `${BASE_URL}/skill/api/v1/skills/GetFile/filedownloadById/${userId}/${temp.fileName}/${temp.fileId}?Authorization=${auth}`,
          };
        }
        return {
          ...com,
          fileExist: false,
        };
      });
      console.log("newStatedd", newState);
      return {
        ...state,
        CompanyList: newState,
      };
    },

    editCompany: (state, action) => {
      const { id, updatedData } = action.payload;
      const index = state.CompanyList.findIndex((company) => company?.id === id);
      if (index !== -1) {
        state.CompanyList[index] = {
          ...state.CompanyList[index],
          ...updatedData,
        };
      }
    },
    deleteCompany: (state, action) => {
      console.log(action.payload);
      const companyId = action.payload;
      state.CompanyList = state.CompanyList.filter(
        (company) => company?.id !== companyId
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCompanyListByUserId.pending, (state) => {
        state.companyApiStatus = "loading";
        state.companyApiError = null;
        state.companyLoading = true;
        // state.CompanyList = [];
      })
      .addCase(GetCompanyListByUserId.fulfilled, (state, action) => {
        try {
          let data = action.payload;

          state.CompanyList = data;
          state.companyApiStatus = "success";
          state.companyApiError = null;
          state.companyLoading = false;
          console.log("jd Lists ", data);
        } catch (error) {
          console.log(error);
   
          // state.CompanyList = [];
        }
      })
      .addCase(GetCompanyListByUserId.rejected, (state, action) => {
        state.companyApiStatus = "error";
        state.companyLoading = false;
        state.companyApiError = action.error.message;
        // state.CompanyList = [];
      });
  },
});

export const {
  setCompanyList,
  emptyCompanyData,
  addCompany,
  editCompany,
  deleteCompany,
  updateCompanyList,
  attachLogoToCompany,
} = UserCompanyDataSlice.actions;
export default UserCompanyDataSlice.reducer;
