import React from "react";
import { Navigate } from "react-router-dom";
import { sessionDecrypt } from "../config/encrypt/encryptData";

const AuthGuardProvider = ({ role, isSkillOwner, children }) => {
  const userRole =sessionDecrypt(sessionStorage.getItem("USER_ROLE"));
  const token = sessionDecrypt(sessionStorage.getItem("token"));

  if (!token || (!role?.includes(userRole) && isSkillOwner)) {
    return <Navigate to={"/"} />;
  } else if (!token || (userRole === "R1" && !role?.includes(userRole))) {
    return <Navigate to={"/skillowner/user-auth"} />;
  } else if (!token || !role?.includes(userRole)) {
    return <Navigate to={"/"} />;
  }

  /* RETURN CHILDREN */
  return children;
};

export default AuthGuardProvider;
