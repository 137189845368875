import axios from "axios";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

const PostApi = async (form, body) => {
  const language =sessionDecrypt(sessionStorage.getItem("HLang"));
  const headers = {
    Authorization: "Bearer " + sessionDecrypt(sessionStorage.getItem("token")),
  };

  const userId =sessionDecrypt(sessionStorage.getItem('userId'));
  body.userId = userId;
  body.lang = language;
  body.mlanguage = language;
  body.mLanguage = language;

  return axios
    .post(`${BASE_URL}/skill/api/v1/skills/create/${form}`, body, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default PostApi;





