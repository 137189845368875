import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { useSelector } from 'react-redux';
export const fetchCountryRegional = createAsyncThunk("countries/RegionalSettings", async () => {
    // console.log("selected Lang: ");
   
    
    try {
        
        
        const response = await fetch(`${BASE_URL}/skill/api/v1/skills/RegionalData/Regional Settings?searchFieldName=mlanguage&searchValue=EN-US`);
       
        const data = await response.json();
        console.log("datas ", data);
        return data;
    } catch (error) {
        throw error;
    }
});