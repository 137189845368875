import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

export const fetchLanguageLists = createAsyncThunk(
  "languageList/fetchData",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/skill/api/v1/skills/get-master-data/Language List`
      );
      return res?.data;
    } catch (error) {
      throw error;
    }
  }
);

const languageListSlice = createSlice({
  name: "languageLists",
  initialState: {
    status: "idle",
    error: null,
    data: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguageLists.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLanguageLists.fulfilled, (state, action) => {
        state.status = "succeeded";
        const homeLang =sessionDecrypt(sessionStorage.getItem("HLang"));
        state.data = action.payload?.filter(
          (data) => data?.dataLanguage === homeLang && data?.mstatus === "Yes"
        );
      })
      .addCase(fetchLanguageLists.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default languageListSlice.reducer;
