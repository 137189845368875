import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import BackToTop from "../../components/BackToTop/BackToTop";
import DeleteFormDetailedProfile from "../../components/DeleteFormDetailedProfile";
import useContentLabel from "../../hooks/useContentLabel";

const ConsoleLayout = ({ children }) => {
  /* LOCATION */
  const { pathname } = useLocation();

  /* LABEL HOOK */
  const contentLabel = useContentLabel();

  const activePath = useMemo(() => {
    switch (pathname) {
      case "/console/attachment":
        return contentLabel("Attachments", "nf Attachments");
      case "/console/validations":
        return contentLabel("MyValidations", "nf Validations");
      case "/console/view-validation":
        return contentLabel("ViewValidation", "nf View Validation");
      default:
        return "";
    }
  }, [contentLabel, pathname]);
  return (
    <div className="content-body">
      <div className="container-fluid">
        <DeleteFormDetailedProfile />
        <div className="profile-management-layout">
          <div
            className="row page-titles card"
            style={{ backgroundColor: "#fff" }}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {contentLabel("Console", "nf Console")}
              </li>
              <li className="breadcrumb-item active">{activePath}</li>
            </ol>
          </div>
          {children}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default ConsoleLayout;
