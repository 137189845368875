import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// react-bootstrap
import { Card, Dropdown, ListGroup } from "react-bootstrap";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project import
import ChatList from "./ChatList";

// assets
import { useDispatch, useSelector } from "react-redux";
import {
  GetAttachment,
  splitStringToObject,
} from "../../../../api/Attachment  API/DownloadAttachmentApi";
import avatar1 from "../../../../assets/images/user/avatar-1.jpg";
import avatar2 from "../../../../assets/images/user/avatar-2.jpg";
import avatar3 from "../../../../assets/images/user/avatar-3.jpg";
import avatar4 from "../../../../assets/images/user/avatar-4.jpg";
import LazyLoadingImageComponent from "../../../../components/Lazy Loading Images/LazyLoadingImageComponent";
import { GetUserForSelectedLanguage } from "../../../../components/SkillOwner/HelperFunction/GetUserForSelectedLanguage";
import { images } from "../../../../constants";
import useContentLabel from "../../../../hooks/useContentLabel";
import { logout } from "../../../../reducer/userDetails/UserProfileSlice";
import { logoutUser } from "../../../../Store";
import LanguageComponent from "../../../../components/LanguageComponent";
import Flag from "../../../../components/FlagAndLang/Flag";
import { sessionDecrypt } from "../../../../config/encrypt/encryptData";

// ==============================|| NAV RIGHT ||============================== //
const NavRight = () => {
  /* USER ROLE */
  const role_ = sessionDecrypt(sessionStorage.getItem("USER_ROLE"));

  /* CONTENT HOOK */
  const contentLabel = useContentLabel();

  /* STATES */
  const [listOpen, setListOpen] = useState(false);
  const [profilePicObj, setProfilePicObj] = useState({});
  const [isProfileImgLoading, setIsProfileImgLoading] = useState(true);
  const [picObjLoading, setPicObjLoading] = useState(false);

  /* DISPATCH */
  const dispatch = useDispatch();

  /* STORES */
  const selectedLanguage = useSelector((state) => state.language);
  const roles = useSelector((state) => state.roles.data);
  const currentRole = useSelector((state) => state.roles.currentRole);
  const [roleDisplay, setRoleDisplay] = useState("");
  const userDetails = useSelector((state) =>
    GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage)
  );

  const notiData = [
    {
      name: "Joseph William",
      image: avatar2,
      details: "Purchase New Theme and make payment",
      activity: "30 min",
    },
    {
      name: "Sara Soudein",
      image: avatar3,
      details: "currently login",
      activity: "30 min",
    },
    {
      name: "Suzen",
      image: avatar4,
      details: "Purchase New Theme and make payment",
      activity: "yesterday",
    },
  ];

  useEffect(() => {
    setPicObjLoading(true);
    setProfilePicObj(splitStringToObject(userDetails?.profilePictureFileName));
    setPicObjLoading(false);
  }, [userDetails?.profilePictureFileName]);

  useEffect(() => {
    const matchedRole = roles.find(
      (role) =>
        role.roleName === currentRole && role.mlanguage === selectedLanguage
    );

    if (matchedRole) {
      setRoleDisplay(matchedRole?.label);
    } else {
      const matchedRole = roles.find(
        (role) => role.roleName === currentRole && role.mlanguage === "EN"
      );
      setRoleDisplay(matchedRole?.label);
    }
  }, [currentRole, roles, selectedLanguage]);

  /* HANDLE IMG LOADING */
  const handleImgLoading = useCallback(() => {
    setIsProfileImgLoading(false);
  }, []);

  /* HANDLE LOGOUT */
  const handleLogout = () => {
    logoutUser();
    dispatch(logout());
    sessionStorage.clear();
  };

  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto">
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown align="end">
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              id="dropdown-basic"
            >
              {/* notification bell icon  */}
              {/* DO NOT REMOVE THESE COMMENT CODE GOING TO USE IN FEATURE */}

              {/* <button type="button" className="btn position-relative">
                <div className="feather icon-bell icon fs-5" />
                <span
                  className="position-absolute start-100 translate-middle badge rounded-pill bg-danger"
                  style={{ fontSize: "9px", top: "-5px" }}
                >
                  16<span className="visually-hidden">unread messages</span>
                </span>
              </button> */}
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="end"
              className="notification notification-scroll"
            >
              <div className="noti-head">
                <h6 className="d-inline-block m-b-0">Notifications</h6>
                <div className="float-end">
                  <Link
                    to="#"
                    style={{ textDecoration: "none" }}
                    className="m-r-10"
                  >
                    mark as read
                  </Link>
                  <Link style={{ textDecoration: "none" }} to="#">
                    clear all
                  </Link>
                </div>
              </div>
              <PerfectScrollbar>
                <ListGroup
                  as="ul"
                  bsPrefix=" "
                  variant="flush"
                  className="noti-body"
                >
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">NEW</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Card
                      className="d-flex align-items-center shadow-none mb-0 p-0"
                      style={{ flexDirection: "row", backgroundColor: "unset" }}
                    >
                      <img
                        className="img-radius"
                        src={avatar1}
                        alt="Generic placeholder"
                      />
                      <Card.Body className="p-0">
                        <p>
                          <strong>John Doe</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock me-2" />
                            30 min
                          </span>
                        </p>
                        <p>New ticket Added</p>
                      </Card.Body>
                    </Card>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">EARLIER</p>
                  </ListGroup.Item>
                  {notiData.map((data, index) => {
                    return (
                      <ListGroup.Item
                        key={index}
                        as="li"
                        bsPrefix=" "
                        className="notification"
                      >
                        <Card
                          className="d-flex align-items-center shadow-none mb-0 p-0"
                          style={{
                            flexDirection: "row",
                            backgroundColor: "unset",
                          }}
                        >
                          <img
                            className="img-radius"
                            src={data.image}
                            alt="Generic placeholder"
                          />
                          <Card.Body className="p-0">
                            <p>
                              <strong>{data.name}</strong>
                              <span className="n-time text-muted">
                                <i className="icon feather icon-clock me-2" />
                                {data.activity}
                              </span>
                            </p>
                            <p>{data.details}</p>
                          </Card.Body>
                        </Card>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </PerfectScrollbar>
              <div className="noti-footer">
                <Link to="#">show all</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown>
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              className="displayChatbox"
              onClick={() => setListOpen(true)}
            >
              {/* mail icon */}
              {/* DO NOT REMOVE THESE COMMENT CODE GOING TO USE IN FEATURE */}
              {/* <button type="button" className="btn  position-relative">
                <div className="icon feather icon-mail fs-5" />
                <span
                  className="position-absolute start-100 translate-middle badge rounded-pill bg-danger  "
                  style={{ fontSize: "9px", top: "-5px" }}
                >
                  12
                  <span className="visually-hidden">unread messages</span>
                </span>
              </button> */}
            </Dropdown.Toggle>
          </Dropdown>
        </ListGroup.Item>

        <ListGroup.Item as="li" bsPrefix=" ">
          <Flag />
        </ListGroup.Item>

        {/*  lang */}
        <ListGroup.Item as="li" bsPrefix=" ">
          <LanguageComponent />
        </ListGroup.Item>

        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown align="end" className="drp-user">
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              id="dropdown-basic"
            >
              {userDetails?.profilePictureFileName &&
              userDetails?.profilePictureFileName.length > 2 &&
              profilePicObj?.fileName ? (
                <img
                  style={{
                    objectFit: "cover",
                  }}
                  src={GetAttachment(
                    userDetails?.id,
                    profilePicObj?.fileName,
                    profilePicObj?.fileId
                  )}
                  alt="User Profile"
                  className={`rounded-circle user-profile-picture ${
                    isProfileImgLoading && "skeleton-loading-img"
                  }`}
                  onError={(e) => {
                    e.target.src = images.user;
                  }}
                  onLoad={handleImgLoading}
                />
              ) : (
                <LazyLoadingImageComponent
                  src={images.user}
                  alt="Profile picture"
                  className={`rounded-circle user-profile-picture ${
                    (picObjLoading || isProfileImgLoading) &&
                    "skeleton-loading-img"
                  }`}
                  onLoad={handleImgLoading}
                />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="profile-notification">
              <div className="pro-head">
                {userDetails?.profilePictureFileName &&
                userDetails?.profilePictureFileName.length > 2 ? (
                  <LazyLoadingImageComponent
                    style={{
                      objectFit: "cover",
                    }}
                    src={GetAttachment(
                      userDetails?.id,
                      profilePicObj?.fileName,
                      profilePicObj?.fileId
                    )}
                    alt="User Profile"
                    className="rounded-circle user-profile-picture"
                  />
                ) : (
                  <LazyLoadingImageComponent
                    src={images.user}
                    alt="Profile picture"
                    className="rounded-circle user-profile-picture"
                  />
                )}
                <span>
                  {userDetails?.firstName}&nbsp;
                  {userDetails?.lastName && userDetails?.lastName}
                </span>
                <br />
                <span className="px-5  mx-1" style={{ color: "#fff" }}>
                  {roleDisplay}
                </span>
                <Link
                  to={"/"}
                  onClick={handleLogout}
                  className="dud-logout"
                  title={contentLabel("Logout", "nf Logout")}
                >
                  <i className="feather icon-log-out" />
                </Link>
              </div>
              {/* <div className="pro-head">
                <span className=" px-5">Skill Owner</span>
              </div> */}
              {/* <ListGroup
                as="ul"
                bsPrefix=" "
                variant="flush"
                className="pro-body"
              >
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-settings" /> Settings
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-user" /> Profile
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-mail" /> My Messages
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-lock" /> Lock Screen
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link
                    to={role_ === "R1" ? "/skillowner/user-auth" : "/"}
                    className="dropdown-item"
                    onClick={handleLogout}
                  >
                    <i className="feather icon-log-out" /> {contentLabel('Logout', 'nf Logout')}
                  </Link>
                </ListGroup.Item>
              </ListGroup> */}
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
    </React.Fragment>
  );
};

export default NavRight;
