import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FetchJdList } from "../../../api/SkillSeeker/FetchJdList.js";
import { GetJdSkillAndQnWithId } from "../../../api/SkillSeeker/job detail/GetJdSkillAndQnWithId.js";
import { showErrorToast } from "../../../components/ToastNotification/showErrorToast.js";
import { useDispatch } from 'react-redux';
import store from "../../../Store.js";



const initialState = {
    remoteCheckBox: false,
    data: {
        "jdsType": "Internal",
        "jdType": "",
        "userCompaniesId": "",
        "jdCompany": "",
        "description": "",
        "title": "",
        "jdsQualification": "",
        "salaryLow": "",
        "salaryHigh": "",
        "currency": "",
        "responsibilities": "",
        "requirements": "",
        "benefits": "",
        "openings": "1",
        "deadline": "",
        "experience": "0",
        "experienceUnit": "",
        "jobLocation": [],
        "mstatus": "New",
        "userId": "",
        "mlanguage": "",
        "favorite": "No",
        "numSkills": 0,
        "jdCategoryName": "",
        "jdSubCategoryName": "",
        "experienceLevel": "",
        "externalSite": "",
        "jdCatId": "",
        "jdSubCatId": "",
        "jdExpLvlId": "",
        "extSiteId": "",
    },
    skills: [],
    questions: [],
    attachmentAdded: false,
    attachment: [],
    postLoading: false,
    isEdit: false,
}

const OpportunityCreationSlice = createSlice({
    name: "OpportunityCreation",
    initialState: {
        remoteCheckBox: false,
        data: {
            "jdsType": "Internal",
            "jdType": "",
            "userCompaniesId": "",
            "jdCompany": "",
            "description": "",
            "title": "",
            "jdsQualification": "",
            "salaryLow": "",
            "salaryHigh": "",
            "currency": "",
            "responsibilities": "",
            "requirements": "",
            "benefits": "",
            "openings": "1",
            "deadline": "",
            "experience": "0",
            "experienceUnit": "",
            "jobLocation": [],
            "mstatus": "New",
            "userId": "",
            "mlanguage": "",
            "favorite": "No",
            "numSkills": 0,
            "jdCategoryName": "",
            "jdSubCategoryName": "",
            "experienceLevel": "",
            "externalSite": "",
            "jdCatId": "",
            "jdSubCatId": "",
            "jdExpLvlId": "",
            "extSiteId": "",
        },
        skills: [],
        questions: [],
        attachmentAdded: false,
        attachment: [],
        postLoading: false,
        isEdit: false,
    },
    reducers: {
        setData: (state, action) => {
            const fieldName = action.payload.fieldName;

            state.data[fieldName] = action.payload.value;
            console.log(fieldName, " ", state.data[fieldName]);

        },
        setLocation: (state, action) => {

            state.data.jobLocation = action.payload;
            console.log("jobLocation", " ", state.data.jobLocation);

        },
        setDataForEdit: (state, action) => {

            console.log("data for edit ", action.payload);
            state.data = action.payload;
            state.isEdit = true;
            // console.log(fieldName, " ", state.data[fieldName]);

        },
        setRemoteCheckBox: (state, action) => {

            state.remoteCheckBox = action.payload;
            console.log("remoteCheckBox", " ", state.remoteCheckBox);

        },
        setOpportunitySkills: (state, action) => {

            state.skills = action.payload;
            console.log("skills", " ", state.skills);

        },
        setOpportunityQuestions: (state, action) => {

            state.questions = action.payload;
            console.log("questions", " ", state.questions);

        },
        setAttachment: (state, action) => {

            state.attachment = action.payload;
            state.attachmentAdded = true;
            console.log("attachment", " ", action.payload);

        },
        removeAttachment: (state, action) => {

            state.attachment = [];
            state.attachmentAdded = false;
            console.log("attachment", " ", state.attachment);

        },
        setLoading: (state, action) => {

            state.postLoading = action.payload;

            console.log("postLoading", " ", state.postLoading);

        },

        //   logout reset to initial state
        emptyOpCreate: (state) => {
            return initialState;

        },


    },


});

export const { emptyOpCreate, setData, setLocation, setRemoteCheckBox, setAttachment, setLoading, setDataForEdit, removeAttachment,
    setOpportunitySkills, setOpportunityQuestions } = OpportunityCreationSlice.actions;
export default OpportunityCreationSlice.reducer;



