import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { useSelector } from 'react-redux';
import { sessionDecrypt } from "../../config/encrypt/encryptData";
export const getAllSubCategoryApi = createAsyncThunk("category/AllSubCategories", async () => {
    // console.log("selected Lang: ");
    const lang = sessionDecrypt(sessionStorage.getItem('HLang'));
    const token = sessionDecrypt(sessionStorage.getItem("token"));
    try {


        const response = await fetch(`${BASE_URL}/skill/api/v1/skills/getdataByLanguage/JDSubCategory/${lang}?authToken=${token}`);

        const data = await response.json();
        console.log("datas ", data);
        return data;
    } catch (error) {
        throw error;
    }
});