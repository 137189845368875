import axios from "axios";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

const EditApi = async (form, id,body) => {


  return axios
    .put(`${BASE_URL}/skill/api/v1/skills/edit/${form}/${id}?authToken=${sessionDecrypt(sessionStorage.getItem("token"))}`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default EditApi;
