import CryptoJS from "crypto-js";
export function encryptData(dataToEncrypt) {
  var randomKey = generateRandomText();
  var randomIv = generateRandomText();
  var key = CryptoJS.enc.Base64.parse(randomKey);
  var initVector = CryptoJS.enc.Base64.parse(randomIv);
  var utf8Stringified = CryptoJS.enc.Utf8.parse(dataToEncrypt);
  var encryptedData = CryptoJS.AES.encrypt(utf8Stringified.toString(), key, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: initVector,
  });
  return (
    randomKey +
    randomIv +
    encryptedData.ciphertext.toString(CryptoJS.enc.Base64)
  );
}

/**
 * <p>
 * Method to dynamically generate text by math.random
 * </p>
 *
 * @returns dynamically generated 24 digit string
 */
function generateRandomText() {
  return "xxxxxxxxxxx/xxxxxxxxxx==".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const sessionEncrypt = (dataToEncrypt) => {
  const encryptedToken = CryptoJS.AES.encrypt(
    dataToEncrypt,
    secretKey
  ).toString();
  return encryptedToken;
};
const secretKey = process.env.SECRET_KEY || "MySecretKey"; // set the secret key in .env file

export const sessionDecrypt = (storedEncryptedToken) => {
  if (storedEncryptedToken) {
    // Decrypt the token
    const bytes = CryptoJS.AES.decrypt(storedEncryptedToken, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } else {
    console.log("No token found");
  }
};
