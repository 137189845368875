import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

export const fetchTrainingHistory = createAsyncThunk(
    "DetailProfile/Trainings",
    async () => {
        try {
            const token = sessionDecrypt(sessionStorage.getItem("token"));
            const userId =sessionDecrypt(sessionStorage.getItem('userId'));
            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/get-all-user-data-by-userId/Training/${userId}?authToken=${token}`);

            const data = await response.json();
            console.log(data);
            return data;
        } catch (error) {
            throw error;
        }
    }
);
