import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPdfReady:false
}

const detailedProfileReportSlice = createSlice({
    name: "detailedProfileReportSlice",
    initialState,
    reducers: {
        setIsPdfReady: (state, action) => {
         state.isPdfReady = action.payload; 
        },

    }
});

export const { setIsPdfReady } = detailedProfileReportSlice.actions;
export default detailedProfileReportSlice.reducer;