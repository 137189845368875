import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getJdExperienceLevelApi } from "../../../api/Jd Category, Jd Exp, External sites/getJdExperienceLevelApi";
const JdExperienceLevelSlice = createSlice({
    name: "JdExperienceLevel",
    initialState: {
        exprieinceLevelData: [],
        status: "idle",
        error: null,
    },
    reducers: {
        setLanguageContent: (state, action) => {
            state.language = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getJdExperienceLevelApi.pending, (state) => {
                state.exprieinceLevelData = [];
                state.status = "loading";
                state.error = null;
            })
            .addCase(getJdExperienceLevelApi.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;

                state.exprieinceLevelData = action.payload;
            })
            .addCase(getJdExperienceLevelApi.rejected, (state, action) => {
                state.status = "failed";
                state.exprieinceLevelData = [];
                state.error = action.error.message;
            });
    },
});

export default JdExperienceLevelSlice.reducer;

