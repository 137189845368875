import React, { lazy, Suspense } from "react";
import ReportsAndViewsLayout from "../../views/reports and views/ReportsAndViewsLayout";
const reportsRoutes = [
  {
    exact: "true",
    path: "skill-profile",
    layout: ReportsAndViewsLayout,
    element: lazy(() =>
      import("../../views/reports and views/skill profile/SkillprofileReport")
    ),
  },
  {
    exact: "true",
    path: "detailed-profile",
    layout: ReportsAndViewsLayout,
    element: lazy(() =>
      import("../../views/reports and views/detailed profile/DetailedprofileReport")
    ),
  },
  {
    exact: "true",
    path: "timeline",
    layout: ReportsAndViewsLayout,
    element: lazy(() =>
      import("../../views/reports and views/timeline/TimelineReport")
    ),
  },
];

export default reportsRoutes;
