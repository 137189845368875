import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

export const FetchDetailedDataByUsedId = async (userId) => {
  try {
    const token = sessionDecrypt(sessionStorage.getItem("token"));

    const response = await fetch(
      `${BASE_URL}/skill/api/v1/skills/getUserDetailedProfile/${userId}?Authorization=${token}`
    );

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    throw error;
  }
};
