import axios from "axios";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

const UserDetailsApi = async () => {
  const token = sessionDecrypt(sessionStorage.getItem("token"));
  const userName = sessionDecrypt(sessionStorage.getItem("userName"));
  return axios
    .get(
      `${BASE_URL}/skill/api/v1/skills/get-all-user-data/User_Details/${userName}?authToken=${token}`
    )
    .then((response) => {
      console.log("response", response);
      return response;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
};

export default UserDetailsApi;
