export const BASE_URL_PATH = '/basic/dashboard';
export const BASE_TITLE = ' | Gradient Able Premium React + Admin Template';

export const CONFIG = {
  layout: 'vertical',
  layoutType: 'menu-light'
};

// if (sessionStorage.getItem('direction') === null) {
//   sessionStorage.setItem('direction', 'ltr');
// }
// export const DIRECTION = sessionStorage.getItem('direction');
