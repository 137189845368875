import React from "react";
import { Col } from "react-bootstrap";
import { icons } from "../../../constants";

const BriefDescriptionTextArea = ({
  limit = 1000,
  label,
  htmlFor,
  isRequired,
  textareaClass,
  id,
  name,
  onChange,
  value,
  row = 3,
  isBold = false,
  tooltipValue=false
}) => {
  return (
    <React.Fragment>
      <label htmlFor={htmlFor} className={`form-label ${isBold && " fw-bold"}`}>
        {label}
        {isRequired && <span className="text-danger">*</span>}
        {
          tooltipValue &&
        <span
          className="ms-1 mb-1"
          data-tooltip-id="my-tooltip"
          data-tooltip-content={
            tooltipValue
          }
        >
          <icons.InfoOutlinedIcon
            className="text-muted"
            style={{ fontSize: "20px" }}
          />
        </span>
        }
      </label>
      <textarea
        className={`form-control ${textareaClass}`}
        id={id}
        rows={row}
        name={name}
        onChange={onChange}
        value={value}
      ></textarea>
      <Col className="text-secondary text-muted text-end w-100">
        {limit - value?.length}
      </Col>
    </React.Fragment>
  );
};

export default BriefDescriptionTextArea;
