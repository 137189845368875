import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewDayOutlinedIcon from "@mui/icons-material/ViewDayOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CiLocationOn } from "react-icons/ci";
import {
  FaAngleLeft,
  FaAngleRight,
  FaChevronCircleUp,
  FaChevronDown,
  FaEdit,
  FaLinkedin,
  FaRegHeart,
  FaStar,
  FaTimes,
  FaTrash,
  FaSearch
} from "react-icons/fa";
import { FaCircle, FaSquareFacebook, FaSquareXTwitter } from "react-icons/fa6";
import { IoCheckmarkSharp, IoCloudDownloadOutline } from "react-icons/io5";
import { LuSplitSquareHorizontal } from "react-icons/lu";
import { MdOpenInNew, MdOutlineWatchLater, MdPlayCircleOutline, MdEdit } from "react-icons/md";
import { PiSuitcaseSimpleDuotone } from "react-icons/pi";
import { RxEnterFullScreen } from "react-icons/rx";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CallMissedOutgoingOutlinedIcon from "@mui/icons-material/CallMissedOutgoingOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import { GiSkills } from "react-icons/gi";
import { CgDetailsMore } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { BiSelectMultiple } from "react-icons/bi";
import { PiProjectorScreenChartFill } from "react-icons/pi";
import { TiCancel } from "react-icons/ti";
import { MdAdd } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { GoPlusCircle } from "react-icons/go";
import { FaAngleDown, FaAngleUp, FaUserSecret } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import { BsPersonLinesFill, BsIncognito } from "react-icons/bs";
import { AiFillPrinter, AiOutlineClose } from 'react-icons/ai';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CiBookmark } from "react-icons/ci";
import { TbCategory } from "react-icons/tb";
import { BiLinkExternal } from "react-icons/bi";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { TbClearAll } from "react-icons/tb";
import { HiOutlineDocumentDownload } from "react-icons/hi";

const icons = {
  AttachMoneyIcon,
  AccessTimeIcon,
  CalendarTodayIcon,
  LocalOfferOutlinedIcon,
  FaChevronDown,
  FaTimes,
  MdOpenInNew,
  IoCheckmarkSharp,
  IoCloudDownloadOutline,
  PiSuitcaseSimpleDuotone,
  MdOutlineWatchLater,
  CiLocationOn,
  FaLinkedin,
  FaRegHeart,
  FaStar,
  FaSquareFacebook,
  FaSquareXTwitter,
  RxEnterFullScreen,
  LuSplitSquareHorizontal,
  ModeEditOutlineOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  MoreHorizIcon,
  SearchIcon,
  ViewDayOutlinedIcon,
  TaskAltOutlinedIcon,
  AddOutlinedIcon,
  ShieldOutlinedIcon,
  AttachFileOutlinedIcon,
  FaChevronCircleUp,
  BusinessCenterOutlinedIcon,
  FmdGoodOutlinedIcon,
  AccessTimeOutlinedIcon,
  CallMissedOutgoingOutlinedIcon,

  MoreVertIcon,
  GiSkills,
  CgDetailsMore,
  FaPlus,
  BiSelectMultiple,
  PiProjectorScreenChartFill,
  TiCancel,
  MdAdd,
  IoClose,
  FaEdit,
  FaTrash,
  FaAngleRight,
  FaAngleLeft,
  FaCircle,
  ThumbUpAltOutlinedIcon,
  ThumbDownAltOutlinedIcon,
  ManageSearchOutlinedIcon,
  FactCheckOutlinedIcon,
  SchoolOutlinedIcon,
  GoPlusCircle,
  FaSearch,
  MdPlayCircleOutline,
  MdEdit,
  CloudDownloadOutlinedIcon,
  CloseOutlinedIcon,
  FaAngleDown,
  FaAngleUp,
  IoIosAddCircle,
  PsychologyOutlinedIcon,
  BsPersonLinesFill,
  EmojiEventsOutlinedIcon,
  SendOutlinedIcon,
  AiFillPrinter,
  AiOutlineClose,
  PrintRoundedIcon,
  VisibilityOffIcon,
  NoAccountsIcon,
  FaUserSecret,
  BsIncognito,
  EmailOutlinedIcon,
  PhoneEnabledOutlinedIcon,
  InfoOutlinedIcon,
  CiBookmark,
  TbCategory,
  BiLinkExternal,
  MdOutlineVerifiedUser,
  TbClearAll,
  HiOutlineDocumentDownload
};
export default icons;
