import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MakeAttachmentRelationPostApi } from "../../api/Attachment  API/Attachment Console/MakeRelationApi";
import AttachmentPost from "../../api/Attachment  API/AttachmentPost";
import { fetchUserAttachment } from "../../reducer/attachments/getUserAttachmentSlice";
import SmallLoader from "../SkillAvailer/SmallLoader";
import { GetUserForSelectedLanguage } from "../SkillOwner/HelperFunction/GetUserForSelectedLanguage";
import { showErrorToast } from "../ToastNotification/showErrorToast";
import AddButton from "../atoms/Buttons/AddButton";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

const AddNewAttachmentComponent = () => {
  /* DISPATCH */
  const dispatch = useDispatch();
  /* USER ID */
  const userId = sessionDecrypt(sessionStorage.getItem("userId"));
  /* USER ROLE */
  const role_ = sessionDecrypt(sessionStorage.getItem("USER_ROLE"));
  /* USE REFS */
  const fileInputRef = useRef(null);

  /* STATES */
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [isNoSpaceError, setIsNoSpaceErro] = useState(false);
  const [showAttachmentError, setShowAttachmentError] = useState(false);
  const [storagePercentage, setStoragePercentage] = useState(0);
  const [isStorageFull, setIsStorageFull] = useState(false);

  const {
    language: selectedLanguage,
    getUserAttachment: { userAttachmentData },
    content,
  } = useSelector((state) => state);
  const userDetails = useSelector((state) =>
    GetUserForSelectedLanguage(state.userProfile.data, selectedLanguage)
  );

  /* CALCULATE PERCENTAGE FOR STORAGE */
  useEffect(() => {
    const absoluteData = userAttachmentData?.filter(
      (att) =>
        att?.linkedApplicationName === userDetails?.applicationName &&
        att?.roleName === role_
    );

    if (absoluteData?.length) {
      let totalFileSize = 0;
      absoluteData.forEach((data) => {
        totalFileSize += Number(data?.fileSize || 0);
      });

      /* MAXIMUM SIZE OF FILE */
      const maxFileSize = Number(
        (
          content[selectedLanguage].find(
            (item) => item.elementLabel === "MaxStorageCapacity"
          ) || {}
        ).mvalue || "nf MaxStorageCapacity"
      );
      let percentage = (totalFileSize / maxFileSize) * 100;

      /* RESTICT % NOT TO EXCEED 100 */
      percentage = Math.min(percentage, 100);

      setStoragePercentage(Math.round(percentage));
    } else {
      setStoragePercentage(0);
    }
  }, [
    role_,
    userAttachmentData,
    userDetails?.applicationName,
    content,
    selectedLanguage,
  ]);

  /* HANDLE ATTACHMENTS */
  const handleAttachmentUpload = useCallback(
    async (files) => {
      if (files.length === 0) {
        setShowAttachmentError(false);
        return;
      }

      /* CHECK FILE SIZE */
      const largeFiles = files.some(
        (file) =>
          file?.size >
          Number(
            (
              content[selectedLanguage].find(
                (item) => item.elementLabel === "MaxFileSize"
              ) || {}
            ).mvalue || "nf MaxFileSize"
          )
      );
      if (largeFiles) {
        setShowAttachmentError(true);
        setTimeout(() => {
          setShowAttachmentError(false);
        }, 5000);
        return;
      } else {
        setShowAttachmentError(false);
      }

      /* CALC TOTAL FILE SIZE */
      const absoluteData = userAttachmentData?.filter(
        (att) =>
          att?.linkedApplicationName === userDetails?.applicationName &&
          att?.roleName === role_
      );

      let totalFileSize = 0;
      absoluteData.forEach((data) => {
        totalFileSize += Number(data?.fileSize || 0);
      });

      const maxFileSize = Number(
        (
          content[selectedLanguage].find(
            (item) => item.elementLabel === "MaxStorageCapacity"
          ) || {}
        ).mvalue || "nf MaxStorageCapacity"
      );
      const remainingStorageCapacity = maxFileSize - totalFileSize;

      /* CHECKING TOTAL SIZE OF THE FILES TO BE UPLOAD */
      const totalSelectedFileSize = files.reduce(
        (acc, file) => acc + file.size,
        0
      );

      if (totalSelectedFileSize > remainingStorageCapacity) {
        setIsNoSpaceErro(true);
        setTimeout(() => {
          setIsNoSpaceErro(false);
        }, 5000);
        return;
      } else {
        setIsNoSpaceErro(false);
      }

      if (storagePercentage >= 100) {
        setIsStorageFull(true);
        setTimeout(() => {
          setIsStorageFull(false);
        }, 5000);
        return;
      } else {
        setIsStorageFull(false);
      }

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });

      try {
        setIsAttachmentLoading(true);
        const res = await AttachmentPost("handleMultiFile", userId, formData);
        const data = res?.data;

        const newUploadedFiles = data.map((file, index) => ({
          filename: file.fileName,
          fileId: file.fileId,
          fileTitle: "",
          fileSize: files[index]?.size,
          fileType: file?.fileType || files[index]?.type,
        }));

        /* ATTACHMENT RELATION PROMISE */
        const attachmentRelationPromises = newUploadedFiles.map((file) => {
          const body = {
            userId: userId,
            fileId: file.fileId,
            fileName: file.filename,
            fileTitle: "",
            fileSize: file?.fileSize,
            fileType: file?.fileType,
            linkedId: userId,
            linkedApplicationName: userDetails?.applicationName,
            roleName: role_,
          };
          return MakeAttachmentRelationPostApi(body);
        });

        try {
          await Promise.all(attachmentRelationPromises);
          /* DISPATCH DATA */
          dispatch(fetchUserAttachment());
        } catch (error) {
          console.error("Error POSTing attachment map: ", error);
        }

        setIsAttachmentLoading(false);
      } catch (error) {
        setIsAttachmentLoading(false);
        console.error("Error uploading files: ", error);
      }
    },
    [
      userId,
      dispatch,
      userDetails?.applicationName,
      role_,
      storagePercentage,
      userAttachmentData,
      content,
      selectedLanguage,
    ]
  );
  /* HANDLE ADD NEW ATTACHMENT */
  const handleAddFiles = useCallback(() => {
    if (selectedFiles.length > 0) {
      handleAttachmentUpload(selectedFiles);
      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [handleAttachmentUpload, selectedFiles]);

  /* HANDLE NEW FILE SELECT */
  const handleFileSelect = useCallback((e) => {
    setSelectedFiles(Array.from(e.target.files));
  }, []);

  /* SHOW ERROR MESSAGE */
  useEffect(() => {
    if (isNoSpaceError) {
      showErrorToast(
        (
          content[selectedLanguage].find(
            (item) => item.elementLabel === "NoSpace"
          ) || {}
        ).mvalue || "nf No Space"
      );
    } else if (showAttachmentError) {
      showErrorToast(
        (
          content[selectedLanguage].find(
            (item) => item.elementLabel === "FileTooLarge"
          ) || {}
        ).mvalue || "nf File size too large"
      );
    } else if (isStorageFull) {
      showErrorToast(
        (
          content[selectedLanguage].find(
            (item) => item.elementLabel === "StorageFull"
          ) || {}
        ).mvalue || "nf Storage full"
      );
    }
  }, [
    content,
    selectedLanguage,
    isNoSpaceError,
    isStorageFull,
    showAttachmentError,
  ]);
  return (
    <div className="input-group w-75">
      <input
        type="file"
        className="form-control text-primary"
        id="inputGroupFile04"
        aria-describedby="inputGroupFileAddon04"
        aria-label="Upload"
        onChange={handleFileSelect}
        accept=".pdf,.jpeg,.jpg,.png,.xlsx,.docx,.mp4"
        ref={fileInputRef}
        multiple
      />
      <AddButton
        disabled={!selectedFiles?.length || isAttachmentLoading}
        onClick={handleAddFiles}
      >
        {!isAttachmentLoading ? (
          (
            content[selectedLanguage].find(
              (item) => item.elementLabel === "AddSkillButton"
            ) || {}
          ).mvalue || "nf Add"
        ) : (
          <SmallLoader height={"10px"} width={"10px"} color={"#fff"} />
        )}
      </AddButton>
    </div>
  );
};

export default AddNewAttachmentComponent;
