import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConfigContext } from "../../../context/ConfigContext";
import useWindowSize from "../../../hooks/useWindowSize";
import seekerMenuItems from "../../../menu-items/seeker-menu-items";
import skillOwnerMenuItems from "../../../menu-items/skill-owner-menu-items";
import skillingAgencyMenuItems from "../../../menu-items/skilling-agency-menu-items";
import NavContent from "./NavContent";
import { sessionDecrypt } from "../../../config/encrypt/encryptData";

const Navigation = () => {
  const configContext = useContext(ConfigContext);
  const { layoutType, collapseMenu } = configContext.state;
  const windowSize = useWindowSize();

  /* STORE IMPORTS */
  const {
    roles: { currentRole },
  } = useSelector((state) => state);
  const fetchedMenuItemsR1 = useSelector((state) => state.menuItems.dataR1);
  const fetchedMenuItemsR2 = useSelector((state) => state.menuItems.dataR2);
  const fetchedMenuItemsR3 = useSelector((state) => state.menuItems.dataR3);

  const scroll = () => {
    document.querySelector(".pcoded-navbar").removeAttribute("style");
  };

  /* STATES */
  const [navigationMenuItems, setNavigationMenuItems] =
    useState(skillOwnerMenuItems);

  const [r1Menu, setR1Menu] = useState([]);
  const [r2Menu, setR2Menu] = useState([]);
  const [r3Menu, setR3Menu] = useState([]);

  useEffect(() => {
    setR1Menu({ items: fetchedMenuItemsR1 || [] });
    setR2Menu({ items: fetchedMenuItemsR2 || [] });
    setR3Menu({ items: fetchedMenuItemsR3 || [] });
  }, [currentRole, fetchedMenuItemsR1, fetchedMenuItemsR2]);

  /* THESE ARE MENU ITEMS WHICH IS STORED src>menu-items, first check with redux to help initially and then use data from local storage */
  useEffect(() => {
    let selectedMenu = [];

    // Ensure currentRole is checked correctly
    const role =
      currentRole || sessionDecrypt(sessionStorage.getItem("USER_ROLE"));

    switch (role) {
      case "R2":
        selectedMenu = r2Menu;
        break;
      case "R1":
        selectedMenu = r1Menu;
        break;
      case "R3":
        selectedMenu = r3Menu;
        break;
      default:
        selectedMenu = skillOwnerMenuItems;
    }

    // Safely set the navigation menu items (even if empty)
    setNavigationMenuItems({ items: selectedMenu.items || [] });
  }, [currentRole, r1Menu, r2Menu, r3Menu]);

  let navClass = ["pcoded-navbar", layoutType, "d-print-none"];
  navClass = [...navClass, "menupos-fixed"];
  window.removeEventListener("scroll", scroll, false);

  if (windowSize.width < 992 && collapseMenu) {
    navClass = [...navClass, "mob-open"];
  } else if (collapseMenu) {
    navClass = [...navClass, "navbar-collapsed"];
  }

  let navBarClass = ["navbar-wrapper", "d-print-none"];
  let navContent = (
    <div className={navBarClass.join(" ")}>
      <NavContent navigation={navigationMenuItems?.items} />
    </div>
  );
  if (windowSize.width < 992) {
    navContent = (
      <div className="navbar-wrapper">
        <NavContent navigation={navigationMenuItems?.items} />
      </div>
    );
  }
  return (
    <React.Fragment>
      <nav className={navClass.join(" ")}>{navContent}</nav>
    </React.Fragment>
  );
};

export default Navigation;
