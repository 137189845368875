import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { GetAllExternalLinksApi } from "../../api/Jd Category, Jd Exp, External sites/GetAllExternalLinksApi";

const ExternalLinksSlice = createSlice({
    name: "ExternalLinks",
    initialState: {
        externalLinks: [],
        status: "idle",
        error: null,
    },
    reducers: {
        setLanguageContent: (state, action) => {
            state.language = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllExternalLinksApi.pending, (state) => {
                state.externalLinks = [];
                state.status = "loading";
                state.error = null;
            })
            .addCase(GetAllExternalLinksApi.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;

                state.externalLinks = action.payload;
            })
            .addCase(GetAllExternalLinksApi.rejected, (state, action) => {
                state.status = "failed";
                state.externalLinks = [];
                state.error = action.error.message;
            });
    },
});

export default ExternalLinksSlice.reducer;

