
import React, { useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import BackToTop from '../../components/BackToTop/BackToTop';
import CheckoutStepper from '../../components/Steppers/Stepper';
import { FaAngleLeft } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import selectedOpportunity, { setSelectedOpportunityData } from '../../reducer/opportunities/selectedOpportunity';
import { sessionDecrypt } from '../../config/encrypt/encryptData';

const useQueryParams = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
  
    return {
      id: queryParams.get('id'),
      name: queryParams.get('name'),
    };
  };

const Opportunities = ({ children }) => {
    const { pathname } = useLocation()
     const { id, name } = useQueryParams();
    const dispatch = useDispatch()

    const activePath = useMemo(() => {
        if (pathname === '/owner/opportunities/view-opportunities' || name) {
            return 'View Opportunities'
        }
    }, [pathname])

    useEffect(() => {
        dispatch(setSelectedOpportunityData(null))
    }, [pathname])


    return (
        <div class="content-body">
            <div class="container-fluid">

                {/* <Outlet />  */}

                <div className="profile-management-layout">
                    <div class="row page-titles card" style={{ backgroundColor: '#fff' }}>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Opportunities</a></li>
                            <li class={`breadcrumb-item  ${name ? '' : 'active'}`} ><Link to={'/owner/opportunities/view-opportunities'}>{activePath}</Link></li>
                            {name && <li class="breadcrumb-item active"><a href="javascript:void(0);">{sessionDecrypt(name)}</a></li>}
                        </ol>
                    </div>


                    {children}

                </div>
            </div>
            <BackToTop />
        </div>
    );
}

export default Opportunities
