import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { sessionDecrypt } from "../../config/encrypt/encryptData";

export const FetchJdList = createAsyncThunk(
    "FetchJdList/fetchData",
    async (body) => {
        try {
            const token = sessionDecrypt(sessionStorage.getItem("token"));
            const userId =sessionDecrypt(sessionStorage.getItem('userId'));

            const response = await fetch(`${BASE_URL}/skill/api/v1/skills/dynamic/JDS?filter=userId%3A${userId}%7Cmstatus%3A%21ARCHIVE%7CjdsType%3A%21SEARCH${body.filter}&start=${body.start}&size=${body.size}&sortField=${body.sortField}&sortOrder=${body.sortOrder}&authToken=${token}`);
            //dynamics/JQuestions?filter=%20jid%3AJDS-3367912&start=0&size=25&sortOrder=desc&authToken=c28a4b254192a6e37147ee8533afa6ca
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
);
