import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/Properties";
import { getAllCategoryApi } from "../../api/Jd Category, Jd Exp, External sites/getAllCategoryApi";
import { getAllSubCategoryApi } from "../../api/Jd Category, Jd Exp, External sites/getAllSubCategoryApi";



const CategoryAndSubCategorySlice = createSlice({
    name: "CategoryAndSubCategory",
    initialState: {
        categories: [],
        subCategories: [],
        categoryApiStatus: "idle",
        subCategoryApiStatus: "idle",
        categoryApiError: null,
        subCategoryApiError: null,
    },
    reducers: {
        setLanguageContent: (state, action) => {
            state.language = action.payload;
        },
        getSubCatsBySelectedCategory: (state, action) => {
            try {
                return state.subCategories.filter(catg => catg.jdCatId === action.payload);
            } catch (error) {
                console.log(error);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCategoryApi.pending, (state) => {
                state.categories = [];
                state.categoryApiStatus = "loading";

            })
            .addCase(getAllCategoryApi.fulfilled, (state, action) => {
                state.categoryApiStatus = "succeeded";


                state.categories = action.payload;
            })
            .addCase(getAllCategoryApi.rejected, (state, action) => {
                state.categoryApiStatus = "failed";
                state.categories = [];
                state.categoryApiError = action.error.message;
            })
            .addCase(getAllSubCategoryApi.pending, (state) => {
                state.subCategories = [];
                state.subCategoryApiStatus = "loading";
            })
            .addCase(getAllSubCategoryApi.fulfilled, (state, action) => {
                state.subCategories = action.payload;
                state.subCategoryApiStatus = "succeeded";

            })
            .addCase(getAllSubCategoryApi.rejected, (state, action) => {
                state.subCategories = [];
                state.subCategoryApiStatus = "failed";
                state.subCategoryApiError = action.error.message;
            });
    },
});



export const { getSubCatsBySelectedCategory } = CategoryAndSubCategorySlice.actions;
export default CategoryAndSubCategorySlice.reducer;

